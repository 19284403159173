<template>
  <div ref="body">
    <custom-header active="global" :subActive="current"></custom-header>
    
    <div class="content-container">
      <!-- <div class="img-box">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/register-banner.jpg" alt="观众预登记">
      </div> -->

      <!-- 观众预登记 -->
      <div class="pre-container">
        
        <h1>Visitor pre-registration</h1>
        <div class="section">
          <div class="title">Individual Registration</div>
          <div class="ul1">
            Register online until March 15, 2025 and receive a FREE four-day pass (200 RMB onsite). You may use this pass to  enter and exit multiple times during the specified time of exhibition operation.
          </div>
          <div class="ul1">
            Pre-registered visitors will be given an electronic visitor pass and can directly enter the exhibition halls. Please note, (the electronic visitor pass is only for personal use and non-transferable).
          </div>
          <!-- <ul class="ul1">
            <li></li>
          </ul> -->
        </div>
        <a :href="'https://reg40.szcreativeweek.com/h5/code?l=' + limitCode" class="button text-bold" target="_blank" rel="nofollow noopener noreferrer">PRE-REGISTER NOW!</a>
        
        <div class="section">
          <div class="title">Admission</div>
          <ul class="ul2">
            <li>1. All visitors must register with their real names. Please fill in your personal identification information correctly, register with your mobile phone number, and bring valid ID (ID card, passport, or family visit permit) to the site for verification.</li>
            <li>2. The exhibition is open to professional audiences and admission is strictly prohibited for individuals under the age of 18.</li>
            <li>3. The QR code obtained after successful registration is for personal use only and cannot be transferred to others.</li>
          </ul>
        </div>
        
        <div class="section">
          <div class="title">Contact us:</div>
          <div class="content">
            <a href="mailto:luoliting@szfa.com">luoliting@szfa.com</a>
          </div>
        </div>
      
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>

  </div>
</template>

<script>
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter,
    'scroll-top': scrollTop,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen International Furniture Fair: Visitor Pre-Registration 2025', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'Pre-Register Online Until March 15, 2025 and Receive Free Four-Day Pass (150 RMB Onsite). Take Part in South China’s Most Innovative Furniture Exhibition!'},
    ],
  },
  data() {
    return {
      current: 'register',
      limitCode: '5C8523-E1A-F9',
    }
  },
  created() {
    if (this.$route.query.l) {
      this.limitCode = this.$route.query.l;
    }
    // /register?k=group
    if (this.$route.query.k === 'group') {
      this.$router.push('/group')
    }
  },
  methods: {
    
  },
}
</script>

<style scoped lang="less">
  
@purplish-red: #E4007F; // 紫红色
@cyan: #00ABA5; // 紫红色

.text-red {
  color: red;
}

// 移动端
@media screen and (max-width: 1023px) {

  .content-container {
    width: 375px;
    margin: 15px auto;
    
    .img-box {
      background-color: #ededed;
      width: 100%;
      
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    
    .group-container,
    .pre-container {
      padding: 0 20px;
      margin-bottom: 90px;
      
      h1 {
        font-family: 'Poppins-Medium';
        text-align: center;
        margin-bottom: 24px;
        font-size: 16px;
      }
      .title {
        font-family: 'Poppins-Bold';
        font-size: 14px;
        margin: 5px 0 8px;
      }
      .sub-title {
        font-family: 'Poppins-Medium';
        font-size: 12px;
        margin: 5px 0;
      }
      
      .ul1,
      .ul2 {
        font-size: 12px;
        margin-bottom: 5px;
        
        li {
          margin-bottom: 5px;
        }
      }
      .ul1 {
        list-style: disc;
        list-style-position: inside;
      }
      
      .button {
        font-family: 'Poppins-Medium';
        display: block;
        background-color: @purplish-red;
        color: #fff;
        border: none;
        width: 180px;
        padding: 6px 0;
        margin: 18px auto;
        font-size: 14px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
      
      .section {
        margin-bottom: 20px;
        
        .content {
          font-size: 12px;
          margin-bottom: 15px;
          
          a {
            color: #409eff;
          }
        }
        
      }
      
    }

  }
  
}

// PC
@media screen and (min-width: 1024px) {

  .content-container {
    width: 240px;
    margin: 15px auto;
    
    .img-box {
      background-color: #ededed;
      width: 100%;
      
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 1px;
      }
    }

  }
  
  .group-container,
  .pre-container {
    width: 204px;
    margin: 0 auto 40px;
    
    h1 {
      font-family: 'Poppins-Medium';
      text-align: center;
      margin-bottom: 8px;
      font-size: 6px;
    }
    .title {
      font-family: 'Poppins-Bold';
      font-size: 3.6px;
      margin: 1px 0 2px;
    }
    .sub-title {
      font-family: 'Poppins-Medium';
      font-size: 3px;
      margin: 1px 0;
    }
    
    .ul1,
    .ul2 {
      font-size: 3px;
      margin-bottom: 2px;
      
      li {
        margin-bottom: 1px;
      }
    }
    .ul1 {
      list-style: disc;
      list-style-position: inside;
    }
    
    .button {
      font-family: 'Poppins-Medium';
      display: block;
      background-color: @purplish-red;
      color: #fff;
      border: none;
      width: 60px;
      padding: 2px 0;
      margin: 6px auto;
      font-size: 5px;
      text-align: center;
      border-radius: 1.2px;
      cursor: pointer;
    }
    
    .section {
      margin-bottom: 5px;
      
      .content {
        font-size: 3px;
        margin-bottom: 3px;
        
        a {
          color: #409eff;
        }
      }
      
    }
    
  }
  
}

</style>